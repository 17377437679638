import useStyles from '@core_modules/cms/components/cms-renderer/magezon/style';
import MagezonLink from '@core_modules/cms/components/cms-renderer/magezon/MagezonLink';
import React from 'react';
import dynamic from 'next/dynamic';

const Typography = dynamic(() => import('@common_typography'));

const MagezonHeading = (props) => {
    const {
        xs_hide, sm_hide, md_hide, lg_hide, heading_type, text, link, font_weight, font_size, color, align, el_class,
    } = props;
    const styles = useStyles(props);
    let classes = 'magezone-heading ';
    if (xs_hide) classes += 'hidden-mobile ';
    if (sm_hide) classes += 'hidden-sm ';
    if (md_hide) classes += 'hidden-md ';
    if (lg_hide) classes += 'hidden-lg ';

    const style = {};
    style.textTransform = 'uppercase';
    style.width = '100%';
    if (color && color !== '') style.color = color;
    if (font_weight && font_weight !== '') style.fontWeight = font_weight;
    if (font_size && font_size !== '') style.fontSize = `${typeof font_size === 'number' ? `${font_size}px` : `${font_size.replace('px', '')}px`}`;
    if (align && align !== '') style.textAlign = align;

    return (
        <>
            <div className={`${styles.container} ${classes} wrapper ${el_class}`}>
                {link && link !== '' ? (
                    <MagezonLink link={link}>
                        <Typography variant={heading_type || 'h1'} style={style} className={el_class || null}>
                            {text || ''}
                        </Typography>
                    </MagezonLink>
                ) : (
                    <Typography variant={heading_type || 'h1'} style={style}>
                        {text || ''}
                    </Typography>
                )}
            </div>
            <style jsx global>
                {`
                    .wrapper.underline-yellow,
                    .wrapper.underline-red {
                        padding-bottom: 5px;
                        border-bottom: 1px solid #F5F5F5;
                    }
                    .wrapper.underline-yellow h2,
                    .wrapper.underline-red h2 {
                        position: relative;
                        font-size: 20px;
                        font-weight: 700;
                        display: inline-block;
                        width: auto !important;
                        margin: 0;
                    }
                    .wrapper.underline-yellow h2:before,
                    .wrapper.underline-red h2:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: -7.5px;
                        width: 100%;
                    }
                    .wrapper.underline-yellow h2:before {
                        border-bottom: 4px solid #FFE604;
                    }
                    .wrapper.underline-red h2:before {
                        border-bottom: 4px solid #FF0000;
                    }
                    #block-title #new-arrival {
                        width: 134px;
                    }
                    #block-title #event-workshop {
                        width: 201px;
                    }
                    #block-title #featured-brands {
                        width: 187px;
                    }
                    #block-title #latest-video {
                        width: 136px;
                    }
                `}
            </style>
        </>
    );
};

export default MagezonHeading;
